import { default as ImageModalComponentsqCUTaCh2N5Meta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/camera/components/ImageModalComponents.vue?macro=true";
import { default as ImageModalInfoD3kfDER6iNMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/camera/components/ImageModalInfo.vue?macro=true";
import { default as index5SY0YyXDbZMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/camera/index.vue?macro=true";
import { default as indexFbc194bcgQMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/dropout/index.vue?macro=true";
import { default as GlobalGalleryComponentDylBeoDSKjMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/gallery/components/GlobalGalleryComponent.vue?macro=true";
import { default as UserGalleryComponentDjhqJrfOAkMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/gallery/components/UserGalleryComponent.vue?macro=true";
import { default as indexm6JTicYNiNMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/gallery/index.vue?macro=true";
import { default as ImageComponentdzAu9VjYR9Meta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/components/ImageComponent.vue?macro=true";
import { default as ImageDetailsModalWJQmwszKgpMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/components/ImageDetailsModal.vue?macro=true";
import { default as NearbyRecyclingCenterInfoLVP8sSbiGhMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/components/NearbyRecyclingCenterInfo.vue?macro=true";
import { default as RecyclingCenterCardF3KhOqK7oYMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/components/RecyclingCenterCard.vue?macro=true";
import { default as indexOKmLUt45tfMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/index.vue?macro=true";
import { default as indexhYctDBqvyMMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/index.vue?macro=true";
import { default as indexqvVCZx4eBIMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/intro/index.vue?macro=true";
import { default as Step1Componentbn0uIbHqQpMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/intro/stepper/Step1Component.vue?macro=true";
import { default as Step2ComponentIN3qyjNW7VMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/intro/stepper/Step2Component.vue?macro=true";
import { default as Step3ComponentS9RNHpMGlAMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/intro/stepper/Step3Component.vue?macro=true";
import { default as logineE6szhFQ6LMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/login.vue?macro=true";
import { default as logout5dEgG2xSS7Meta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/logout.vue?macro=true";
import { default as ListPosts4rCEXPxfOiMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/posts/components/ListPosts.vue?macro=true";
import { default as PostAU0IRkqs1dMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/posts/components/Post.vue?macro=true";
import { default as indexo2lxeBWSYoMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/posts/index.vue?macro=true";
import { default as indexFbxIa8YKlJMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/profile/index.vue?macro=true";
import { default as indexySj5qKhGkhMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/support/index.vue?macro=true";
import { default as index2IVsLz0gJAMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/tips/index.vue?macro=true";
import { default as DisposalPlaceComponentkzfUtNmoUzMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/your_disposal_places/components/DisposalPlaceComponent.vue?macro=true";
import { default as TrashCanDetailskCz1jjjAO1Meta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/your_disposal_places/components/TrashCanDetails.vue?macro=true";
import { default as indexIMGdwskc1lMeta } from "C:/Users/nacar/IdeaProjects/trash/guis/web/pages/your_disposal_places/index.vue?macro=true";
export default [
  {
    name: ImageModalComponentsqCUTaCh2N5Meta?.name ?? "camera-components-ImageModalComponents___en",
    path: ImageModalComponentsqCUTaCh2N5Meta?.path ?? "/camera/components/ImageModalComponents",
    meta: ImageModalComponentsqCUTaCh2N5Meta || {},
    alias: ImageModalComponentsqCUTaCh2N5Meta?.alias || [],
    redirect: ImageModalComponentsqCUTaCh2N5Meta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/camera/components/ImageModalComponents.vue").then(m => m.default || m)
  },
  {
    name: ImageModalComponentsqCUTaCh2N5Meta?.name ?? "camera-components-ImageModalComponents___es",
    path: ImageModalComponentsqCUTaCh2N5Meta?.path ?? "/es/camera/components/ImageModalComponents",
    meta: ImageModalComponentsqCUTaCh2N5Meta || {},
    alias: ImageModalComponentsqCUTaCh2N5Meta?.alias || [],
    redirect: ImageModalComponentsqCUTaCh2N5Meta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/camera/components/ImageModalComponents.vue").then(m => m.default || m)
  },
  {
    name: ImageModalComponentsqCUTaCh2N5Meta?.name ?? "camera-components-ImageModalComponents___pt",
    path: ImageModalComponentsqCUTaCh2N5Meta?.path ?? "/pt/camera/components/ImageModalComponents",
    meta: ImageModalComponentsqCUTaCh2N5Meta || {},
    alias: ImageModalComponentsqCUTaCh2N5Meta?.alias || [],
    redirect: ImageModalComponentsqCUTaCh2N5Meta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/camera/components/ImageModalComponents.vue").then(m => m.default || m)
  },
  {
    name: ImageModalInfoD3kfDER6iNMeta?.name ?? "camera-components-ImageModalInfo___en",
    path: ImageModalInfoD3kfDER6iNMeta?.path ?? "/camera/components/ImageModalInfo",
    meta: ImageModalInfoD3kfDER6iNMeta || {},
    alias: ImageModalInfoD3kfDER6iNMeta?.alias || [],
    redirect: ImageModalInfoD3kfDER6iNMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/camera/components/ImageModalInfo.vue").then(m => m.default || m)
  },
  {
    name: ImageModalInfoD3kfDER6iNMeta?.name ?? "camera-components-ImageModalInfo___es",
    path: ImageModalInfoD3kfDER6iNMeta?.path ?? "/es/camera/components/ImageModalInfo",
    meta: ImageModalInfoD3kfDER6iNMeta || {},
    alias: ImageModalInfoD3kfDER6iNMeta?.alias || [],
    redirect: ImageModalInfoD3kfDER6iNMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/camera/components/ImageModalInfo.vue").then(m => m.default || m)
  },
  {
    name: ImageModalInfoD3kfDER6iNMeta?.name ?? "camera-components-ImageModalInfo___pt",
    path: ImageModalInfoD3kfDER6iNMeta?.path ?? "/pt/camera/components/ImageModalInfo",
    meta: ImageModalInfoD3kfDER6iNMeta || {},
    alias: ImageModalInfoD3kfDER6iNMeta?.alias || [],
    redirect: ImageModalInfoD3kfDER6iNMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/camera/components/ImageModalInfo.vue").then(m => m.default || m)
  },
  {
    name: index5SY0YyXDbZMeta?.name ?? "camera___en",
    path: index5SY0YyXDbZMeta?.path ?? "/camera",
    meta: index5SY0YyXDbZMeta || {},
    alias: index5SY0YyXDbZMeta?.alias || [],
    redirect: index5SY0YyXDbZMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/camera/index.vue").then(m => m.default || m)
  },
  {
    name: index5SY0YyXDbZMeta?.name ?? "camera___es",
    path: index5SY0YyXDbZMeta?.path ?? "/es/camara",
    meta: index5SY0YyXDbZMeta || {},
    alias: index5SY0YyXDbZMeta?.alias || [],
    redirect: index5SY0YyXDbZMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/camera/index.vue").then(m => m.default || m)
  },
  {
    name: index5SY0YyXDbZMeta?.name ?? "camera___pt",
    path: index5SY0YyXDbZMeta?.path ?? "/pt/camera",
    meta: index5SY0YyXDbZMeta || {},
    alias: index5SY0YyXDbZMeta?.alias || [],
    redirect: index5SY0YyXDbZMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/camera/index.vue").then(m => m.default || m)
  },
  {
    name: indexFbc194bcgQMeta?.name ?? "dropout___en",
    path: indexFbc194bcgQMeta?.path ?? "/dropout",
    meta: indexFbc194bcgQMeta || {},
    alias: indexFbc194bcgQMeta?.alias || [],
    redirect: indexFbc194bcgQMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/dropout/index.vue").then(m => m.default || m)
  },
  {
    name: indexFbc194bcgQMeta?.name ?? "dropout___es",
    path: indexFbc194bcgQMeta?.path ?? "/es/dropout",
    meta: indexFbc194bcgQMeta || {},
    alias: indexFbc194bcgQMeta?.alias || [],
    redirect: indexFbc194bcgQMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/dropout/index.vue").then(m => m.default || m)
  },
  {
    name: indexFbc194bcgQMeta?.name ?? "dropout___pt",
    path: indexFbc194bcgQMeta?.path ?? "/pt/dropout",
    meta: indexFbc194bcgQMeta || {},
    alias: indexFbc194bcgQMeta?.alias || [],
    redirect: indexFbc194bcgQMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/dropout/index.vue").then(m => m.default || m)
  },
  {
    name: GlobalGalleryComponentDylBeoDSKjMeta?.name ?? "gallery-components-GlobalGalleryComponent___en",
    path: GlobalGalleryComponentDylBeoDSKjMeta?.path ?? "/gallery/components/GlobalGalleryComponent",
    meta: GlobalGalleryComponentDylBeoDSKjMeta || {},
    alias: GlobalGalleryComponentDylBeoDSKjMeta?.alias || [],
    redirect: GlobalGalleryComponentDylBeoDSKjMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/gallery/components/GlobalGalleryComponent.vue").then(m => m.default || m)
  },
  {
    name: GlobalGalleryComponentDylBeoDSKjMeta?.name ?? "gallery-components-GlobalGalleryComponent___es",
    path: GlobalGalleryComponentDylBeoDSKjMeta?.path ?? "/es/gallery/components/GlobalGalleryComponent",
    meta: GlobalGalleryComponentDylBeoDSKjMeta || {},
    alias: GlobalGalleryComponentDylBeoDSKjMeta?.alias || [],
    redirect: GlobalGalleryComponentDylBeoDSKjMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/gallery/components/GlobalGalleryComponent.vue").then(m => m.default || m)
  },
  {
    name: GlobalGalleryComponentDylBeoDSKjMeta?.name ?? "gallery-components-GlobalGalleryComponent___pt",
    path: GlobalGalleryComponentDylBeoDSKjMeta?.path ?? "/pt/gallery/components/GlobalGalleryComponent",
    meta: GlobalGalleryComponentDylBeoDSKjMeta || {},
    alias: GlobalGalleryComponentDylBeoDSKjMeta?.alias || [],
    redirect: GlobalGalleryComponentDylBeoDSKjMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/gallery/components/GlobalGalleryComponent.vue").then(m => m.default || m)
  },
  {
    name: UserGalleryComponentDjhqJrfOAkMeta?.name ?? "gallery-components-UserGalleryComponent___en",
    path: UserGalleryComponentDjhqJrfOAkMeta?.path ?? "/gallery/components/UserGalleryComponent",
    meta: UserGalleryComponentDjhqJrfOAkMeta || {},
    alias: UserGalleryComponentDjhqJrfOAkMeta?.alias || [],
    redirect: UserGalleryComponentDjhqJrfOAkMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/gallery/components/UserGalleryComponent.vue").then(m => m.default || m)
  },
  {
    name: UserGalleryComponentDjhqJrfOAkMeta?.name ?? "gallery-components-UserGalleryComponent___es",
    path: UserGalleryComponentDjhqJrfOAkMeta?.path ?? "/es/gallery/components/UserGalleryComponent",
    meta: UserGalleryComponentDjhqJrfOAkMeta || {},
    alias: UserGalleryComponentDjhqJrfOAkMeta?.alias || [],
    redirect: UserGalleryComponentDjhqJrfOAkMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/gallery/components/UserGalleryComponent.vue").then(m => m.default || m)
  },
  {
    name: UserGalleryComponentDjhqJrfOAkMeta?.name ?? "gallery-components-UserGalleryComponent___pt",
    path: UserGalleryComponentDjhqJrfOAkMeta?.path ?? "/pt/gallery/components/UserGalleryComponent",
    meta: UserGalleryComponentDjhqJrfOAkMeta || {},
    alias: UserGalleryComponentDjhqJrfOAkMeta?.alias || [],
    redirect: UserGalleryComponentDjhqJrfOAkMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/gallery/components/UserGalleryComponent.vue").then(m => m.default || m)
  },
  {
    name: indexm6JTicYNiNMeta?.name ?? "gallery___en",
    path: indexm6JTicYNiNMeta?.path ?? "/gallery",
    meta: indexm6JTicYNiNMeta || {},
    alias: indexm6JTicYNiNMeta?.alias || [],
    redirect: indexm6JTicYNiNMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/gallery/index.vue").then(m => m.default || m)
  },
  {
    name: indexm6JTicYNiNMeta?.name ?? "gallery___es",
    path: indexm6JTicYNiNMeta?.path ?? "/es/galeria",
    meta: indexm6JTicYNiNMeta || {},
    alias: indexm6JTicYNiNMeta?.alias || [],
    redirect: indexm6JTicYNiNMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/gallery/index.vue").then(m => m.default || m)
  },
  {
    name: indexm6JTicYNiNMeta?.name ?? "gallery___pt",
    path: indexm6JTicYNiNMeta?.path ?? "/pt/galeria",
    meta: indexm6JTicYNiNMeta || {},
    alias: indexm6JTicYNiNMeta?.alias || [],
    redirect: indexm6JTicYNiNMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/gallery/index.vue").then(m => m.default || m)
  },
  {
    name: ImageComponentdzAu9VjYR9Meta?.name ?? "images-components-ImageComponent___en",
    path: ImageComponentdzAu9VjYR9Meta?.path ?? "/images/components/ImageComponent",
    meta: ImageComponentdzAu9VjYR9Meta || {},
    alias: ImageComponentdzAu9VjYR9Meta?.alias || [],
    redirect: ImageComponentdzAu9VjYR9Meta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/components/ImageComponent.vue").then(m => m.default || m)
  },
  {
    name: ImageComponentdzAu9VjYR9Meta?.name ?? "images-components-ImageComponent___es",
    path: ImageComponentdzAu9VjYR9Meta?.path ?? "/es/images/components/ImageComponent",
    meta: ImageComponentdzAu9VjYR9Meta || {},
    alias: ImageComponentdzAu9VjYR9Meta?.alias || [],
    redirect: ImageComponentdzAu9VjYR9Meta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/components/ImageComponent.vue").then(m => m.default || m)
  },
  {
    name: ImageComponentdzAu9VjYR9Meta?.name ?? "images-components-ImageComponent___pt",
    path: ImageComponentdzAu9VjYR9Meta?.path ?? "/pt/images/components/ImageComponent",
    meta: ImageComponentdzAu9VjYR9Meta || {},
    alias: ImageComponentdzAu9VjYR9Meta?.alias || [],
    redirect: ImageComponentdzAu9VjYR9Meta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/components/ImageComponent.vue").then(m => m.default || m)
  },
  {
    name: ImageDetailsModalWJQmwszKgpMeta?.name ?? "images-components-ImageDetailsModal___en",
    path: ImageDetailsModalWJQmwszKgpMeta?.path ?? "/images/components/ImageDetailsModal",
    meta: ImageDetailsModalWJQmwszKgpMeta || {},
    alias: ImageDetailsModalWJQmwszKgpMeta?.alias || [],
    redirect: ImageDetailsModalWJQmwszKgpMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/components/ImageDetailsModal.vue").then(m => m.default || m)
  },
  {
    name: ImageDetailsModalWJQmwszKgpMeta?.name ?? "images-components-ImageDetailsModal___es",
    path: ImageDetailsModalWJQmwszKgpMeta?.path ?? "/es/images/components/ImageDetailsModal",
    meta: ImageDetailsModalWJQmwszKgpMeta || {},
    alias: ImageDetailsModalWJQmwszKgpMeta?.alias || [],
    redirect: ImageDetailsModalWJQmwszKgpMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/components/ImageDetailsModal.vue").then(m => m.default || m)
  },
  {
    name: ImageDetailsModalWJQmwszKgpMeta?.name ?? "images-components-ImageDetailsModal___pt",
    path: ImageDetailsModalWJQmwszKgpMeta?.path ?? "/pt/images/components/ImageDetailsModal",
    meta: ImageDetailsModalWJQmwszKgpMeta || {},
    alias: ImageDetailsModalWJQmwszKgpMeta?.alias || [],
    redirect: ImageDetailsModalWJQmwszKgpMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/components/ImageDetailsModal.vue").then(m => m.default || m)
  },
  {
    name: NearbyRecyclingCenterInfoLVP8sSbiGhMeta?.name ?? "images-components-NearbyRecyclingCenterInfo___en",
    path: NearbyRecyclingCenterInfoLVP8sSbiGhMeta?.path ?? "/images/components/NearbyRecyclingCenterInfo",
    meta: NearbyRecyclingCenterInfoLVP8sSbiGhMeta || {},
    alias: NearbyRecyclingCenterInfoLVP8sSbiGhMeta?.alias || [],
    redirect: NearbyRecyclingCenterInfoLVP8sSbiGhMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/components/NearbyRecyclingCenterInfo.vue").then(m => m.default || m)
  },
  {
    name: NearbyRecyclingCenterInfoLVP8sSbiGhMeta?.name ?? "images-components-NearbyRecyclingCenterInfo___es",
    path: NearbyRecyclingCenterInfoLVP8sSbiGhMeta?.path ?? "/es/images/components/NearbyRecyclingCenterInfo",
    meta: NearbyRecyclingCenterInfoLVP8sSbiGhMeta || {},
    alias: NearbyRecyclingCenterInfoLVP8sSbiGhMeta?.alias || [],
    redirect: NearbyRecyclingCenterInfoLVP8sSbiGhMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/components/NearbyRecyclingCenterInfo.vue").then(m => m.default || m)
  },
  {
    name: NearbyRecyclingCenterInfoLVP8sSbiGhMeta?.name ?? "images-components-NearbyRecyclingCenterInfo___pt",
    path: NearbyRecyclingCenterInfoLVP8sSbiGhMeta?.path ?? "/pt/images/components/NearbyRecyclingCenterInfo",
    meta: NearbyRecyclingCenterInfoLVP8sSbiGhMeta || {},
    alias: NearbyRecyclingCenterInfoLVP8sSbiGhMeta?.alias || [],
    redirect: NearbyRecyclingCenterInfoLVP8sSbiGhMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/components/NearbyRecyclingCenterInfo.vue").then(m => m.default || m)
  },
  {
    name: RecyclingCenterCardF3KhOqK7oYMeta?.name ?? "images-components-RecyclingCenterCard___en",
    path: RecyclingCenterCardF3KhOqK7oYMeta?.path ?? "/images/components/RecyclingCenterCard",
    meta: RecyclingCenterCardF3KhOqK7oYMeta || {},
    alias: RecyclingCenterCardF3KhOqK7oYMeta?.alias || [],
    redirect: RecyclingCenterCardF3KhOqK7oYMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/components/RecyclingCenterCard.vue").then(m => m.default || m)
  },
  {
    name: RecyclingCenterCardF3KhOqK7oYMeta?.name ?? "images-components-RecyclingCenterCard___es",
    path: RecyclingCenterCardF3KhOqK7oYMeta?.path ?? "/es/images/components/RecyclingCenterCard",
    meta: RecyclingCenterCardF3KhOqK7oYMeta || {},
    alias: RecyclingCenterCardF3KhOqK7oYMeta?.alias || [],
    redirect: RecyclingCenterCardF3KhOqK7oYMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/components/RecyclingCenterCard.vue").then(m => m.default || m)
  },
  {
    name: RecyclingCenterCardF3KhOqK7oYMeta?.name ?? "images-components-RecyclingCenterCard___pt",
    path: RecyclingCenterCardF3KhOqK7oYMeta?.path ?? "/pt/images/components/RecyclingCenterCard",
    meta: RecyclingCenterCardF3KhOqK7oYMeta || {},
    alias: RecyclingCenterCardF3KhOqK7oYMeta?.alias || [],
    redirect: RecyclingCenterCardF3KhOqK7oYMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/components/RecyclingCenterCard.vue").then(m => m.default || m)
  },
  {
    name: indexOKmLUt45tfMeta?.name ?? "images___en",
    path: indexOKmLUt45tfMeta?.path ?? "/images/:id",
    meta: indexOKmLUt45tfMeta || {},
    alias: indexOKmLUt45tfMeta?.alias || [],
    redirect: indexOKmLUt45tfMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKmLUt45tfMeta?.name ?? "images___es",
    path: indexOKmLUt45tfMeta?.path ?? "/es/imagenes/:id",
    meta: indexOKmLUt45tfMeta || {},
    alias: indexOKmLUt45tfMeta?.alias || [],
    redirect: indexOKmLUt45tfMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKmLUt45tfMeta?.name ?? "images___pt",
    path: indexOKmLUt45tfMeta?.path ?? "/pt/imagens/:id",
    meta: indexOKmLUt45tfMeta || {},
    alias: indexOKmLUt45tfMeta?.alias || [],
    redirect: indexOKmLUt45tfMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/images/index.vue").then(m => m.default || m)
  },
  {
    name: indexhYctDBqvyMMeta?.name ?? "index___en",
    path: indexhYctDBqvyMMeta?.path ?? "/",
    meta: indexhYctDBqvyMMeta || {},
    alias: indexhYctDBqvyMMeta?.alias || [],
    redirect: indexhYctDBqvyMMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhYctDBqvyMMeta?.name ?? "index___es",
    path: indexhYctDBqvyMMeta?.path ?? "/es",
    meta: indexhYctDBqvyMMeta || {},
    alias: indexhYctDBqvyMMeta?.alias || [],
    redirect: indexhYctDBqvyMMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhYctDBqvyMMeta?.name ?? "index___pt",
    path: indexhYctDBqvyMMeta?.path ?? "/pt",
    meta: indexhYctDBqvyMMeta || {},
    alias: indexhYctDBqvyMMeta?.alias || [],
    redirect: indexhYctDBqvyMMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexqvVCZx4eBIMeta?.name ?? "intro___en",
    path: indexqvVCZx4eBIMeta?.path ?? "/intro",
    meta: indexqvVCZx4eBIMeta || {},
    alias: indexqvVCZx4eBIMeta?.alias || [],
    redirect: indexqvVCZx4eBIMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/intro/index.vue").then(m => m.default || m)
  },
  {
    name: indexqvVCZx4eBIMeta?.name ?? "intro___es",
    path: indexqvVCZx4eBIMeta?.path ?? "/es/introduccion",
    meta: indexqvVCZx4eBIMeta || {},
    alias: indexqvVCZx4eBIMeta?.alias || [],
    redirect: indexqvVCZx4eBIMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/intro/index.vue").then(m => m.default || m)
  },
  {
    name: indexqvVCZx4eBIMeta?.name ?? "intro___pt",
    path: indexqvVCZx4eBIMeta?.path ?? "/pt/introducao",
    meta: indexqvVCZx4eBIMeta || {},
    alias: indexqvVCZx4eBIMeta?.alias || [],
    redirect: indexqvVCZx4eBIMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/intro/index.vue").then(m => m.default || m)
  },
  {
    name: Step1Componentbn0uIbHqQpMeta?.name ?? "intro-stepper-Step1Component___en",
    path: Step1Componentbn0uIbHqQpMeta?.path ?? "/intro/stepper/Step1Component",
    meta: Step1Componentbn0uIbHqQpMeta || {},
    alias: Step1Componentbn0uIbHqQpMeta?.alias || [],
    redirect: Step1Componentbn0uIbHqQpMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/intro/stepper/Step1Component.vue").then(m => m.default || m)
  },
  {
    name: Step1Componentbn0uIbHqQpMeta?.name ?? "intro-stepper-Step1Component___es",
    path: Step1Componentbn0uIbHqQpMeta?.path ?? "/es/intro/stepper/Step1Component",
    meta: Step1Componentbn0uIbHqQpMeta || {},
    alias: Step1Componentbn0uIbHqQpMeta?.alias || [],
    redirect: Step1Componentbn0uIbHqQpMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/intro/stepper/Step1Component.vue").then(m => m.default || m)
  },
  {
    name: Step1Componentbn0uIbHqQpMeta?.name ?? "intro-stepper-Step1Component___pt",
    path: Step1Componentbn0uIbHqQpMeta?.path ?? "/pt/intro/stepper/Step1Component",
    meta: Step1Componentbn0uIbHqQpMeta || {},
    alias: Step1Componentbn0uIbHqQpMeta?.alias || [],
    redirect: Step1Componentbn0uIbHqQpMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/intro/stepper/Step1Component.vue").then(m => m.default || m)
  },
  {
    name: Step2ComponentIN3qyjNW7VMeta?.name ?? "intro-stepper-Step2Component___en",
    path: Step2ComponentIN3qyjNW7VMeta?.path ?? "/intro/stepper/Step2Component",
    meta: Step2ComponentIN3qyjNW7VMeta || {},
    alias: Step2ComponentIN3qyjNW7VMeta?.alias || [],
    redirect: Step2ComponentIN3qyjNW7VMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/intro/stepper/Step2Component.vue").then(m => m.default || m)
  },
  {
    name: Step2ComponentIN3qyjNW7VMeta?.name ?? "intro-stepper-Step2Component___es",
    path: Step2ComponentIN3qyjNW7VMeta?.path ?? "/es/intro/stepper/Step2Component",
    meta: Step2ComponentIN3qyjNW7VMeta || {},
    alias: Step2ComponentIN3qyjNW7VMeta?.alias || [],
    redirect: Step2ComponentIN3qyjNW7VMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/intro/stepper/Step2Component.vue").then(m => m.default || m)
  },
  {
    name: Step2ComponentIN3qyjNW7VMeta?.name ?? "intro-stepper-Step2Component___pt",
    path: Step2ComponentIN3qyjNW7VMeta?.path ?? "/pt/intro/stepper/Step2Component",
    meta: Step2ComponentIN3qyjNW7VMeta || {},
    alias: Step2ComponentIN3qyjNW7VMeta?.alias || [],
    redirect: Step2ComponentIN3qyjNW7VMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/intro/stepper/Step2Component.vue").then(m => m.default || m)
  },
  {
    name: Step3ComponentS9RNHpMGlAMeta?.name ?? "intro-stepper-Step3Component___en",
    path: Step3ComponentS9RNHpMGlAMeta?.path ?? "/intro/stepper/Step3Component",
    meta: Step3ComponentS9RNHpMGlAMeta || {},
    alias: Step3ComponentS9RNHpMGlAMeta?.alias || [],
    redirect: Step3ComponentS9RNHpMGlAMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/intro/stepper/Step3Component.vue").then(m => m.default || m)
  },
  {
    name: Step3ComponentS9RNHpMGlAMeta?.name ?? "intro-stepper-Step3Component___es",
    path: Step3ComponentS9RNHpMGlAMeta?.path ?? "/es/intro/stepper/Step3Component",
    meta: Step3ComponentS9RNHpMGlAMeta || {},
    alias: Step3ComponentS9RNHpMGlAMeta?.alias || [],
    redirect: Step3ComponentS9RNHpMGlAMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/intro/stepper/Step3Component.vue").then(m => m.default || m)
  },
  {
    name: Step3ComponentS9RNHpMGlAMeta?.name ?? "intro-stepper-Step3Component___pt",
    path: Step3ComponentS9RNHpMGlAMeta?.path ?? "/pt/intro/stepper/Step3Component",
    meta: Step3ComponentS9RNHpMGlAMeta || {},
    alias: Step3ComponentS9RNHpMGlAMeta?.alias || [],
    redirect: Step3ComponentS9RNHpMGlAMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/intro/stepper/Step3Component.vue").then(m => m.default || m)
  },
  {
    name: logineE6szhFQ6LMeta?.name ?? "login___en",
    path: logineE6szhFQ6LMeta?.path ?? "/login",
    meta: logineE6szhFQ6LMeta || {},
    alias: logineE6szhFQ6LMeta?.alias || [],
    redirect: logineE6szhFQ6LMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logineE6szhFQ6LMeta?.name ?? "login___es",
    path: logineE6szhFQ6LMeta?.path ?? "/es/login",
    meta: logineE6szhFQ6LMeta || {},
    alias: logineE6szhFQ6LMeta?.alias || [],
    redirect: logineE6szhFQ6LMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logineE6szhFQ6LMeta?.name ?? "login___pt",
    path: logineE6szhFQ6LMeta?.path ?? "/pt/login",
    meta: logineE6szhFQ6LMeta || {},
    alias: logineE6szhFQ6LMeta?.alias || [],
    redirect: logineE6szhFQ6LMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logout5dEgG2xSS7Meta?.name ?? "logout___en",
    path: logout5dEgG2xSS7Meta?.path ?? "/logout",
    meta: logout5dEgG2xSS7Meta || {},
    alias: logout5dEgG2xSS7Meta?.alias || [],
    redirect: logout5dEgG2xSS7Meta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logout5dEgG2xSS7Meta?.name ?? "logout___es",
    path: logout5dEgG2xSS7Meta?.path ?? "/es/logout",
    meta: logout5dEgG2xSS7Meta || {},
    alias: logout5dEgG2xSS7Meta?.alias || [],
    redirect: logout5dEgG2xSS7Meta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logout5dEgG2xSS7Meta?.name ?? "logout___pt",
    path: logout5dEgG2xSS7Meta?.path ?? "/pt/logout",
    meta: logout5dEgG2xSS7Meta || {},
    alias: logout5dEgG2xSS7Meta?.alias || [],
    redirect: logout5dEgG2xSS7Meta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: ListPosts4rCEXPxfOiMeta?.name ?? "posts-components-ListPosts___en",
    path: ListPosts4rCEXPxfOiMeta?.path ?? "/posts/components/ListPosts",
    meta: ListPosts4rCEXPxfOiMeta || {},
    alias: ListPosts4rCEXPxfOiMeta?.alias || [],
    redirect: ListPosts4rCEXPxfOiMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/posts/components/ListPosts.vue").then(m => m.default || m)
  },
  {
    name: ListPosts4rCEXPxfOiMeta?.name ?? "posts-components-ListPosts___es",
    path: ListPosts4rCEXPxfOiMeta?.path ?? "/es/posts/components/ListPosts",
    meta: ListPosts4rCEXPxfOiMeta || {},
    alias: ListPosts4rCEXPxfOiMeta?.alias || [],
    redirect: ListPosts4rCEXPxfOiMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/posts/components/ListPosts.vue").then(m => m.default || m)
  },
  {
    name: ListPosts4rCEXPxfOiMeta?.name ?? "posts-components-ListPosts___pt",
    path: ListPosts4rCEXPxfOiMeta?.path ?? "/pt/posts/components/ListPosts",
    meta: ListPosts4rCEXPxfOiMeta || {},
    alias: ListPosts4rCEXPxfOiMeta?.alias || [],
    redirect: ListPosts4rCEXPxfOiMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/posts/components/ListPosts.vue").then(m => m.default || m)
  },
  {
    name: PostAU0IRkqs1dMeta?.name ?? "posts-components-Post___en",
    path: PostAU0IRkqs1dMeta?.path ?? "/posts/components/Post",
    meta: PostAU0IRkqs1dMeta || {},
    alias: PostAU0IRkqs1dMeta?.alias || [],
    redirect: PostAU0IRkqs1dMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/posts/components/Post.vue").then(m => m.default || m)
  },
  {
    name: PostAU0IRkqs1dMeta?.name ?? "posts-components-Post___es",
    path: PostAU0IRkqs1dMeta?.path ?? "/es/posts/components/Post",
    meta: PostAU0IRkqs1dMeta || {},
    alias: PostAU0IRkqs1dMeta?.alias || [],
    redirect: PostAU0IRkqs1dMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/posts/components/Post.vue").then(m => m.default || m)
  },
  {
    name: PostAU0IRkqs1dMeta?.name ?? "posts-components-Post___pt",
    path: PostAU0IRkqs1dMeta?.path ?? "/pt/posts/components/Post",
    meta: PostAU0IRkqs1dMeta || {},
    alias: PostAU0IRkqs1dMeta?.alias || [],
    redirect: PostAU0IRkqs1dMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/posts/components/Post.vue").then(m => m.default || m)
  },
  {
    name: indexo2lxeBWSYoMeta?.name ?? "posts___en",
    path: indexo2lxeBWSYoMeta?.path ?? "/learn_more",
    meta: indexo2lxeBWSYoMeta || {},
    alias: indexo2lxeBWSYoMeta?.alias || [],
    redirect: indexo2lxeBWSYoMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: indexo2lxeBWSYoMeta?.name ?? "posts___es",
    path: indexo2lxeBWSYoMeta?.path ?? "/es/aprende_mas",
    meta: indexo2lxeBWSYoMeta || {},
    alias: indexo2lxeBWSYoMeta?.alias || [],
    redirect: indexo2lxeBWSYoMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: indexo2lxeBWSYoMeta?.name ?? "posts___pt",
    path: indexo2lxeBWSYoMeta?.path ?? "/pt/saiba_mais",
    meta: indexo2lxeBWSYoMeta || {},
    alias: indexo2lxeBWSYoMeta?.alias || [],
    redirect: indexo2lxeBWSYoMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: indexFbxIa8YKlJMeta?.name ?? "profile___en",
    path: indexFbxIa8YKlJMeta?.path ?? "/profile",
    meta: indexFbxIa8YKlJMeta || {},
    alias: indexFbxIa8YKlJMeta?.alias || [],
    redirect: indexFbxIa8YKlJMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexFbxIa8YKlJMeta?.name ?? "profile___es",
    path: indexFbxIa8YKlJMeta?.path ?? "/es/perfil",
    meta: indexFbxIa8YKlJMeta || {},
    alias: indexFbxIa8YKlJMeta?.alias || [],
    redirect: indexFbxIa8YKlJMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexFbxIa8YKlJMeta?.name ?? "profile___pt",
    path: indexFbxIa8YKlJMeta?.path ?? "/pt/perfil",
    meta: indexFbxIa8YKlJMeta || {},
    alias: indexFbxIa8YKlJMeta?.alias || [],
    redirect: indexFbxIa8YKlJMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexySj5qKhGkhMeta?.name ?? "support___en",
    path: indexySj5qKhGkhMeta?.path ?? "/support",
    meta: indexySj5qKhGkhMeta || {},
    alias: indexySj5qKhGkhMeta?.alias || [],
    redirect: indexySj5qKhGkhMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexySj5qKhGkhMeta?.name ?? "support___es",
    path: indexySj5qKhGkhMeta?.path ?? "/es/suporte",
    meta: indexySj5qKhGkhMeta || {},
    alias: indexySj5qKhGkhMeta?.alias || [],
    redirect: indexySj5qKhGkhMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexySj5qKhGkhMeta?.name ?? "support___pt",
    path: indexySj5qKhGkhMeta?.path ?? "/pt/suporte",
    meta: indexySj5qKhGkhMeta || {},
    alias: indexySj5qKhGkhMeta?.alias || [],
    redirect: indexySj5qKhGkhMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: index2IVsLz0gJAMeta?.name ?? "tips___en",
    path: index2IVsLz0gJAMeta?.path ?? "/tips",
    meta: index2IVsLz0gJAMeta || {},
    alias: index2IVsLz0gJAMeta?.alias || [],
    redirect: index2IVsLz0gJAMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index2IVsLz0gJAMeta?.name ?? "tips___es",
    path: index2IVsLz0gJAMeta?.path ?? "/es/consejos",
    meta: index2IVsLz0gJAMeta || {},
    alias: index2IVsLz0gJAMeta?.alias || [],
    redirect: index2IVsLz0gJAMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index2IVsLz0gJAMeta?.name ?? "tips___pt",
    path: index2IVsLz0gJAMeta?.path ?? "/pt/dicas",
    meta: index2IVsLz0gJAMeta || {},
    alias: index2IVsLz0gJAMeta?.alias || [],
    redirect: index2IVsLz0gJAMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/tips/index.vue").then(m => m.default || m)
  },
  {
    name: DisposalPlaceComponentkzfUtNmoUzMeta?.name ?? "your_disposal_places-components-DisposalPlaceComponent___en",
    path: DisposalPlaceComponentkzfUtNmoUzMeta?.path ?? "/your_disposal_places/components/DisposalPlaceComponent",
    meta: DisposalPlaceComponentkzfUtNmoUzMeta || {},
    alias: DisposalPlaceComponentkzfUtNmoUzMeta?.alias || [],
    redirect: DisposalPlaceComponentkzfUtNmoUzMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/your_disposal_places/components/DisposalPlaceComponent.vue").then(m => m.default || m)
  },
  {
    name: DisposalPlaceComponentkzfUtNmoUzMeta?.name ?? "your_disposal_places-components-DisposalPlaceComponent___es",
    path: DisposalPlaceComponentkzfUtNmoUzMeta?.path ?? "/es/your_disposal_places/components/DisposalPlaceComponent",
    meta: DisposalPlaceComponentkzfUtNmoUzMeta || {},
    alias: DisposalPlaceComponentkzfUtNmoUzMeta?.alias || [],
    redirect: DisposalPlaceComponentkzfUtNmoUzMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/your_disposal_places/components/DisposalPlaceComponent.vue").then(m => m.default || m)
  },
  {
    name: DisposalPlaceComponentkzfUtNmoUzMeta?.name ?? "your_disposal_places-components-DisposalPlaceComponent___pt",
    path: DisposalPlaceComponentkzfUtNmoUzMeta?.path ?? "/pt/your_disposal_places/components/DisposalPlaceComponent",
    meta: DisposalPlaceComponentkzfUtNmoUzMeta || {},
    alias: DisposalPlaceComponentkzfUtNmoUzMeta?.alias || [],
    redirect: DisposalPlaceComponentkzfUtNmoUzMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/your_disposal_places/components/DisposalPlaceComponent.vue").then(m => m.default || m)
  },
  {
    name: TrashCanDetailskCz1jjjAO1Meta?.name ?? "your_disposal_places-components-TrashCanDetails___en",
    path: TrashCanDetailskCz1jjjAO1Meta?.path ?? "/your_disposal_places/components/TrashCanDetails",
    meta: TrashCanDetailskCz1jjjAO1Meta || {},
    alias: TrashCanDetailskCz1jjjAO1Meta?.alias || [],
    redirect: TrashCanDetailskCz1jjjAO1Meta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/your_disposal_places/components/TrashCanDetails.vue").then(m => m.default || m)
  },
  {
    name: TrashCanDetailskCz1jjjAO1Meta?.name ?? "your_disposal_places-components-TrashCanDetails___es",
    path: TrashCanDetailskCz1jjjAO1Meta?.path ?? "/es/your_disposal_places/components/TrashCanDetails",
    meta: TrashCanDetailskCz1jjjAO1Meta || {},
    alias: TrashCanDetailskCz1jjjAO1Meta?.alias || [],
    redirect: TrashCanDetailskCz1jjjAO1Meta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/your_disposal_places/components/TrashCanDetails.vue").then(m => m.default || m)
  },
  {
    name: TrashCanDetailskCz1jjjAO1Meta?.name ?? "your_disposal_places-components-TrashCanDetails___pt",
    path: TrashCanDetailskCz1jjjAO1Meta?.path ?? "/pt/your_disposal_places/components/TrashCanDetails",
    meta: TrashCanDetailskCz1jjjAO1Meta || {},
    alias: TrashCanDetailskCz1jjjAO1Meta?.alias || [],
    redirect: TrashCanDetailskCz1jjjAO1Meta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/your_disposal_places/components/TrashCanDetails.vue").then(m => m.default || m)
  },
  {
    name: indexIMGdwskc1lMeta?.name ?? "your_disposal_places___en",
    path: indexIMGdwskc1lMeta?.path ?? "/your_disposal_places",
    meta: indexIMGdwskc1lMeta || {},
    alias: indexIMGdwskc1lMeta?.alias || [],
    redirect: indexIMGdwskc1lMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/your_disposal_places/index.vue").then(m => m.default || m)
  },
  {
    name: indexIMGdwskc1lMeta?.name ?? "your_disposal_places___es",
    path: indexIMGdwskc1lMeta?.path ?? "/es/sus_lugares_de_disposicion",
    meta: indexIMGdwskc1lMeta || {},
    alias: indexIMGdwskc1lMeta?.alias || [],
    redirect: indexIMGdwskc1lMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/your_disposal_places/index.vue").then(m => m.default || m)
  },
  {
    name: indexIMGdwskc1lMeta?.name ?? "your_disposal_places___pt",
    path: indexIMGdwskc1lMeta?.path ?? "/pt/seus_locais_de_descarte",
    meta: indexIMGdwskc1lMeta || {},
    alias: indexIMGdwskc1lMeta?.alias || [],
    redirect: indexIMGdwskc1lMeta?.redirect,
    component: () => import("C:/Users/nacar/IdeaProjects/trash/guis/web/pages/your_disposal_places/index.vue").then(m => m.default || m)
  }
]