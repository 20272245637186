import payload_plugin_utWagNCvYQ from "C:/Users/nacar/IdeaProjects/trash/node_modules/.pnpm/nuxt-vuefire@1.0.2_@firebase+app-types@0.9.1_firebase-admin@11.11.1_firebase-functions@4.9.0__jzx6q6mjvvdwdkvcj5hul7lfv4/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.mjs";
import revive_payload_client_ekhqofbhck from "C:/Users/nacar/IdeaProjects/trash/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.0_eslint@9.2.0_f_xjzg63u7ndojm4kkj6du74y534/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_iYay1oMuui from "C:/Users/nacar/IdeaProjects/trash/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.0_eslint@9.2.0_f_xjzg63u7ndojm4kkj6du74y534/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_DdtlbylkJE from "C:/Users/nacar/IdeaProjects/trash/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.0_eslint@9.2.0_f_xjzg63u7ndojm4kkj6du74y534/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_5oqt32uD3Z from "C:/Users/nacar/IdeaProjects/trash/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.0_eslint@9.2.0_f_xjzg63u7ndojm4kkj6du74y534/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_MMRKGPaW0n from "C:/Users/nacar/IdeaProjects/trash/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.0_eslint@9.2.0_f_xjzg63u7ndojm4kkj6du74y534/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "C:/Users/nacar/IdeaProjects/trash/guis/web/.nuxt/components.plugin.mjs";
import prefetch_client_Eaeuy14gD4 from "C:/Users/nacar/IdeaProjects/trash/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.0_eslint@9.2.0_f_xjzg63u7ndojm4kkj6du74y534/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_wiGjsoJqd0 from "C:/Users/nacar/IdeaProjects/trash/node_modules/.pnpm/nuxt-logrocket@2.0.14_patch_hash=sio7xcg44pvd2wjvm7c5udmzky/node_modules/nuxt-logrocket/dist/runtime/plugin.client.mjs";
import plugin_T7flFPuhjw from "C:/Users/nacar/IdeaProjects/trash/node_modules/.pnpm/@hebilicious+vue-query-nuxt@0.3.0_@tanstack+vue-query@5.35.5_nuxt@3.11.2/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import composition_LFvY7etc2o from "C:/Users/nacar/IdeaProjects/trash/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.4_vue-router@4.3.2_vue@3.4.27/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_f1YAlMiqQn from "C:/Users/nacar/IdeaProjects/trash/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.4_vue-router@4.3.2_vue@3.4.27/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_R56eVA4dRV from "C:/Users/nacar/IdeaProjects/trash/node_modules/.pnpm/nuxt-vuefire@1.0.2_@firebase+app-types@0.9.1_firebase-admin@11.11.1_firebase-functions@4.9.0__jzx6q6mjvvdwdkvcj5hul7lfv4/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import vuefire_auth_plugin_client_cCwkwh1j5u from "C:/Users/nacar/IdeaProjects/trash/guis/web/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "C:/Users/nacar/IdeaProjects/trash/guis/web/.nuxt/vuefire-plugin.mjs";
import plugin_t69Rxuw2xf from "C:/Users/nacar/IdeaProjects/trash/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.2_vue@3.4.27/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_ZpzdCMkHyj from "C:/Users/nacar/IdeaProjects/trash/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.12.11_@unocss+reset@0.60.0_eslint@9.2.0_f_xjzg63u7ndojm4kkj6du74y534/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import errorhandler_client_XoPlZZqvQu from "C:/Users/nacar/IdeaProjects/trash/guis/web/plugins/errorhandler.client.ts";
import vuetify_7h9QAQEssH from "C:/Users/nacar/IdeaProjects/trash/guis/web/plugins/vuetify.ts";
export default [
  payload_plugin_utWagNCvYQ,
  revive_payload_client_ekhqofbhck,
  unhead_iYay1oMuui,
  router_DdtlbylkJE,
  payload_client_5oqt32uD3Z,
  check_outdated_build_client_MMRKGPaW0n,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Eaeuy14gD4,
  plugin_client_wiGjsoJqd0,
  plugin_T7flFPuhjw,
  composition_LFvY7etc2o,
  i18n_f1YAlMiqQn,
  plugin_client_R56eVA4dRV,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  plugin_t69Rxuw2xf,
  chunk_reload_client_ZpzdCMkHyj,
  errorhandler_client_XoPlZZqvQu,
  vuetify_7h9QAQEssH
]